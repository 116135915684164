import React, { useState, useEffect } from "react";
import logImg from "./logo-black.png"; // Default logo image
import indianFlagImg from "./indianflag.png";
import './footer.css'; // Import the CSS file

export const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine which logo to display based on window width
  const logoSrc = windowWidth <= 768 ? logImg : logImg;

  // Determine if it's mobile view
  const isMobileView = windowWidth <= 768;

  return (
    <footer className={`footer bg-[#F3F4F8] py-10 ${isMobileView ? 'mobile-view' : ''}`}>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center border-t border-gray-200 pt-6">
        <div className="footer-left flex flex-col items-center md:items-start mb-4 md:mb-0 md:w-1/3">
          <img src={logoSrc} alt="logo" className={`footer-logo h-16 mb-2 ${windowWidth <= 768 ? 'w-auto' : 'w-1/2'}`} />
          <p className="footer-rights text-sm text-black">@2019-24. All Rights Reserved.</p>
          {!isMobileView && (
            <div className="footer-text-bottom-left text-sm text-gray-500 mt-2">
              Developed by AgensiaCo
            </div>
          )}
        </div>

        <div className="footer-center flex flex-col items-center md:items-center mb-4 md:mb-0 md:w-1/3">
          <img src={indianFlagImg} alt="Indian flag" className={`footer-indian-flag mb-2 ${windowWidth > 768 ? 'w-20' : ''}`} />
          <div className="footer-made-in-india flex flex-col items-center space-y-1">
            <span className="footer-text text-sm text-black">Made in India</span>
          </div>
        </div>

        <div className="footer-right flex flex-col md:flex-row items-center justify-end md:w-1/3">
          <div className="footer-links-container flex flex-row md:flex-row">
            <ul className="footer-links-list list-disc list-inside text-sm text-black">
              <li><a href="https://shrividhya.in/customweb/privacy.html" target="_blank" rel="noopener noreferrer" className="footer-link">Privacy Policy</a></li>
              <li><a href="https://shrividhya.in/customweb/terms.html" target="_blank" rel="noopener noreferrer" className="footer-link">Terms of Use</a></li>
              <li><a href="https://shrividhya.in/customweb/refund.html" target="_blank" rel="noopener noreferrer" className="footer-link">Refund Policy</a></li>
            </ul>
            
          </div>
          {!isMobileView && (
            <div className="footer-text-bottom-right text-sm text-gray-500 mt-2">
              {/* Additional footer text if needed */}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};
