import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import "./quizz.css";

const allQuestions = [
  {
    question: "If 2x + 3 = 7, what is x?",
    options: [2, 1, 3, 4],
    answer: 2
  },
  {
    question: "What is the value of 3^3?",
    options: [27, 9, 18, 21],
    answer: 27
  },
  {
    question: "What is 50% of 80?",
    options: [40, 30, 60, 70],
    answer: 40
  },
  {
    question: "If 4x = 12, what is x?",
    options: [3, 2, 4, 6],
    answer: 3
  },
  {
    question: "If y - 4 = 2, what is y?",
    options: [6, 2, 4, 8],
    answer: 6
  },
  {
    question: "What is 7 × 6 - 10?",
    options: [32, 42, 52, 34],
    answer: 32
  },
  {
    question: "What is the sum of the angles in a triangle?",
    options: [180, 360, 90, 270],
    answer: 180
  },
  {
    question: "What is 3/4 of 16?",
    options: [12, 8, 10, 14],
    answer: 12
  },
  {
    question: "If 2x + 5 = 15, what is x?",
    options: [5, 4, 3, 10],
    answer: 5
  },
  {
    question: "What is the value of x in the equation 2x - 6 = 8?",
    options: [7, 5, 6, 8],
    answer: 7
  },
  {
    question: "What is the perimeter of a rectangle with length 5 and width 3?",
    options: [16, 15, 18, 20],
    answer: 16
  },
  {
    question: "If 5x = 25, what is x?",
    options: [5, 4, 3, 6],
    answer: 5
  },
  {
    question: "If 8y = 32, what is y?",
    options: [4, 6, 8, 3],
    answer: 4
  },
  {
    question: "What is the area of a circle with a radius of 3 (Use π ≈ 3.14)?",
    options: [28.26, 31.4, 18.84, 27.26],
    answer: 28.26
  },
  {
    question: "What is the value of 5^2?",
    options: [25, 10, 15, 20],
    answer: 25
  },
  {
    question: "If a rectangle has an area of 24 and a length of 6, what is its width?",
    options: [4, 6, 8, 3],
    answer: 4
  },
  {
    question: "If 9x = 81, what is x?",
    options: [9, 8, 7, 6],
    answer: 9
  },
  {
    question: "What is the square root of 49?",
    options: [7, 6, 5, 8],
    answer: 7
  },
  {
    question: "What is the value of x if 2x + 7 = 13?",
    options: [3, 4, 5, 6],
    answer: 3
  },
  {
    question: "If 12 is divided into 3 equal parts, what is each part?",
    options: [4, 3, 5, 6],
    answer: 4
  }
];

const motivationalMessages = {
  5: "Mathematical Prodigy 🥳",
  4: "Amazing! You're a math genius! 💡",
  3: "Great job! Keep it up! 👍",
  2: "Good effort! Keep practicing! 💪🏼",
  1: "Don't worry, mistakes help us learn! 😊",
  0: "It's just the start, keep trying! 💯"
};

const MathQuiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isQuizOver, setIsQuizOver] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const shuffledQuestions = allQuestions.sort(() => Math.random() - 0.5).slice(0, 5);
    setQuestions(shuffledQuestions);
  }, []);

  const handleOptionClick = (option) => {
    if (selectedOption === null) {
      setSelectedOption(option);
      if (option === questions[currentQuestion].answer) {
        setScore(score + 1);
      }
      setTimeout(() => handleNextQuestion(), 1000);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
    } else {
      setIsQuizOver(true);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 12000);
    }
  };

  const handleRestartQuiz = () => {
    const shuffledQuestions = allQuestions.sort(() => Math.random() - 0.5).slice(0, 5);
    setQuestions(shuffledQuestions);
    setCurrentQuestion(0);
    setScore(0);
    setSelectedOption(null);
    setIsQuizOver(false);
    setShowConfetti(false);
  };

  return (
    <div className="quiz-container">
      {showConfetti && <Confetti recycle={false} numberOfPieces={500} />}
      {!isQuizOver && <div className="score">Score: {score}</div>}
      {isQuizOver ? (
        <div className="score-card">
          <h2>Quiz Over!</h2>
          <p>Your score: {score}/{questions.length}</p>
          <p>{motivationalMessages[score]}</p>
          <button className="restart-button" onClick={handleRestartQuiz}>Restart Quiz</button>
        </div>
      ) : (
        <>
          <h1>Math Game</h1>
          <h2 className="subheading">Let's Play 🎉</h2>
          <div className="question">
            <p><strong>{questions[currentQuestion]?.question}</strong></p>
          </div>
          <div className="options">
            {questions[currentQuestion]?.options.map((option, index) => (
              <button
                key={index}
                className={`option-button ${selectedOption !== null && option === questions[currentQuestion].answer ? 'correct' : selectedOption === option ? 'wrong' : ''}`}
                onClick={() => handleOptionClick(option)}
                disabled={selectedOption !== null}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MathQuiz;
