import React, { useState, useEffect } from 'react';
import './blog.css';
import aboutUsImage from './aboutusimage.png';
import founderImage from './founderimage.jpg';
import desktopHeroImage from './viditabout.png';
import mobileHeroImage from './viditabout-mobile.png';

export const Blog = () => {
  const [heroImage, setHeroImage] = useState(desktopHeroImage);

  useEffect(() => {
    const updateHeroImage = () => {
      if (window.innerWidth <= 800) {
        setHeroImage(mobileHeroImage);
      } else {
        setHeroImage(desktopHeroImage);
      }
    };

    updateHeroImage();
    window.addEventListener('resize', updateHeroImage);

    return () => window.removeEventListener('resize', updateHeroImage);
  }, []);

  return (
    <>
      <section className="hero-container" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-text text-center">
          
        </div>
      </section>

      <section className="container content">
        <div className="row content-section">
          <div className="col-lg-6 col-md-12 mb-4">
            <img src={aboutUsImage} alt="About Us" className="img-fluid rounded" />
          </div>
          <div className="col-lg-6 col-md-12 text-container p-4 rounded bg-white">
            <h2>Our Story</h2>
            <br />
            <p>
              Started in 2019 with a very small setup, Shrividhya Classes humbly grew with love and nurturing from its students and their parents.
              Strong value system, students first approach in all decisions have been pivotal in shaping up the institute as it is today. 
              We are very proud of what we do at Shrividhya Classes because our work is directly shaping up the future of the lives we touch.
            </p>
          </div>
        </div>

        <div className="row content-section">
          <div className="col-lg-6 col-md-12 text-container p-4 rounded bg-white mb-4">
            <h2>Our Founder</h2>
            <h3 className="founder-title">Vidit Aggarwal</h3>
            <h3 className="founder-subtitle">BE, DCE, 2013</h3>

            <br />
            <p>
              Maths has always been the first love of my life. Whenever I used to feel down, I used to practice maths. 
              Other kids would like to watch cartoons or play, but I would study maths.
              I want to offer the best of me to the world. The only way I know to do this is by teaching maths.
            </p>
          </div>
          <div className="col-lg-6 col-md-12">
            <img src={founderImage} alt="Founder" className="img-fluid rounded" />
          </div>
        </div>
      </section>
    </>
  );
};
