import React from 'react';
import Slider from 'react-slick';
import image1 from './slider1.png';
import image2 from './slider2.png';
import image3 from './2.png';
import image4 from './image4.png';
// Import CSS for slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css'; // Import the updated CSS file

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    fade: true,
  };

  return (
    <div className='slider-container'>
      <Slider {...settings}>
        <div className='slider-item'>
          <img src={image1} alt='Slide 1' className='slider-image' />
          <div className='slider-text'>
            <a href="/internal-page-link" className='slider-button'>Learn More</a>
          </div>
        </div>
        <div className='slider-item'>
          <img src={image2} alt='Slide 2' className='slider-image' />
          <div className='slider-text'>
            <a href="https://ibdp.shrividhya.in/" target="_blank" rel="noopener noreferrer" className='slider-button'>Join Now</a>
          </div>
        </div>
        <div className='slider-item'>
          <img src={image3} alt='Slide 3' className='slider-image' />
          <div className='slider-text'>
            <a href="/another-internal-page" className='slider-button'>Learn More</a>
          </div>
        </div>
        <div className='slider-item'>
          <img src={image4} alt='Slide 4' className='slider-image' />
          <div className='slider-text'>
            <a href="/some-page" className='slider-button'>Learn More</a>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;
