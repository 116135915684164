import React from "react";
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import './Instructor.css'; // Ensure you import the CSS file

export const Instructor = () => {
  return (
    <section className='contact-us mb-16'>
      <div className='container'>
        <div className='heading py-12 text-center w-2/3 m-auto md:w-full'>
          <h1 className='text-3xl font-semibold text-black'>Contact Us</h1>
          <p className='text-[14px] mt-2 block'>Open for any suggestion.</p>
        </div>
        <div className='content flex flex-wrap'>
          <div className='contact-info w-full md:w-1/2 pr-8'>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3493.331613557096!2d77.0842516!3d28.4214668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d238e09b10403%3A0x2eff2b879e0973fa!2sMathematics%20%26%20Physics%20by%20Shrividhya%20Classes!5e0!3m2!1sen!2sin!4v1627366143243!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className='w-full rounded-md'
            ></iframe>
          </div>
          <div className='contact-details w-full md:w-1/2'>
            <div className='box'>
              <h4>ADDRESS:</h4>
              <p>G block, 2919, Sushant Lok Phase 3, Sushant Lok 2, Sector 57, Gurugram, Haryana 122003</p>
            </div>
            <div className='box'>
              <h4>EMAIL:</h4>
              <p>contact@shrividhya.in</p>
            </div>
            <div className='box'>
              <h4>PHONE:</h4>
              <p>+91-8130711689</p>
            </div>
            <div className='contact-buttons'>
              <a href='https://wa.me/918130711689' target='_blank' rel='noopener noreferrer' className='contact-button whatsapp flex items-center mb-4'>
                <FaWhatsapp className='icon mr-2' /> WhatsApp
              </a>
              <a href='tel:+918130711689' className='contact-button call flex items-center mb-4'>
                <MdPhone className='icon mr-2' /> Call 
              </a>
              <a href='mailto:contact@shrividhya.in' className='contact-button email flex items-center'>
                <MdEmail className='icon mr-2' /> Email 
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
