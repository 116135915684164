import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components/common/Layout";
import Home from "./pages/Home";
import About from "./pages/home-about/About";  // Corrected import
import { Instructor } from "./pages/Instructor";
import { Blog } from "./pages/About/Blog";
// Assuming you have a Courses component

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/instructor"
          element={
            <Layout>
              <Instructor />
            </Layout>
          }
        />
        <Route
          path='/blog'
          element={
            <Layout>
              <Blog />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
