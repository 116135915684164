import React from 'react';
import './FeatureSection.css'; // Import the CSS file for custom styles

const FeatureSection = () => {
  return (
    <section className="feature-section">
      <h2 className="feature-section-title">What makes us unique?</h2>
      <div className="feature-grid">
        <div className="feature-item feature-item-1">
          <h3>Questionbank</h3>
          <p>
            The #1 IB Mathematics Exam Questionbank for IB Students. Thousands of IB exam style questions (AA & AI), filtered by topic, sub-topic & difficulty. Video solutions & mark-schemes for all questions. Practice & master IB exam style questions.
          </p>
        </div>
        <div className="feature-item feature-item-2">
          <h3>Past Papers</h3>
          <p>
            Step-by-step video solutions to Past IB Math Exams. Short, easy to follow video tutorials taught by experienced IB Math teachers.
          </p>
        </div>
        <div className="feature-item feature-item-3">
          <h3>Practice Exams</h3>
          <p>
            Test yourself with a library of IB Math Practice Exams (AA & AI). Sub-topic Quizzes, Full Topic Exams, Mock Exam Papers, Difficulty Ladder Exams and more.
          </p>
        </div>
        <div className="feature-item feature-item-4">
          <h3>Key Concepts</h3>
          <p>
            Short summary videos that teach the key concepts tested in IB Math Exams. Perfect for learning from home.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
