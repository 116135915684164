import React from "react";
import About from "./home-about/About";  
import MathQuiz from "./mathquiz/MathQuiz";  
import FeatureSection from "./Features/Features";
import Testimonial from "./testimonial/Testimonial";
import "./home.css"
import ImageSlider from "./imageslider/ImageSlider";


export const Home = () => {
  return (
    <>
      <HomeContent />
      <ImageSlider />
      <div className="spacer" />
      <MathQuiz />
      <div className="spacer" />
      <FeatureSection />
      <div className="spacer" />
      <Testimonial />
      <div className="spacer" /> {/* Added spacer to manage space between Testimonial and About */}
      <About />
    </>
  );
};

export const HomeContent = () => {
  return (
    <>
      {/* Hero section */}
      <div className="hero-container">
        <div className="container">
          <div className="flex items-center justify-center md:flex-col h-full">
            <div className="right w-1/2 md:w-full relative">
              <div className="images relative">
                <div className="img h-[80vh] w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
