import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import image1 from './Reuben.jpeg';
import image2 from './image2.png';
import image3 from './image3.png';
import image4 from './image4.jpeg';

function App() {
  const [expandedReview, setExpandedReview] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const handleReadMore = (name) => {
    setExpandedReview(name);
  };

  const handleClose = () => {
    setExpandedReview(null);
  };

  return (
    <div className='w-full md:w-3/4 m-auto'>
      <div className="mt-20">
        <Slider {...settings}>
          {data.map((d) => (
            <div key={d.name} className="bg-white h-auto text-black rounded-xl relative p-4">
              <div className='h-56 bg-white-500 flex justify-center items-center rounded-t-xl'>
                <img src={d.img} alt="" className="h-44 w-44 rounded-full"/>
              </div>

              <div className={`flex flex-col items-center justify-between gap-4 ${expandedReview === d.name ? 'max-h-[250px] overflow-y-auto' : ''}`}>
                <div className="text-center">
                  <p className="text-xl font-semibold">{d.name}</p>
                  {d.subheading && (
                    <p className="text-md text-gray-500 mt-1">{d.subheading}</p> // Reduced margin-top
                  )}
                </div>
                <p className="text-center">
                  {expandedReview === d.name ? d.longReview : d.shortReview}
                </p>
                <div className="w-full flex justify-center mt-4">
                  {expandedReview === d.name ? (
                    <button 
                      className='bg-red-500 text-white text-lg px-6 py-1 rounded-xl'
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  ) : (
                    <button 
                      className='bg-indigo-500 text-white text-lg px-6 py-1 rounded-xl'
                      onClick={() => handleReadMore(d.name)}
                      style={{ marginBottom: '1rem' }} // Adjusted margin for better visibility
                    >
                      Read More
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

const truncateReview = (review, maxWords) => {
  return review.split(' ').slice(0, maxWords).join(' ') + (review.split(' ').length > maxWords ? '...' : '');
}

const data = [
  {
    name: `Reuben`,
    subheading: `Mathematics Enthusiast`,
    img: image1,
    shortReview: truncateReview(`I am immensely grateful to Shrividhya Classes for their instrumental role in my academic journey.`, 80),
    longReview: `I am immensely grateful to Shrividhya Classes for their instrumental role in my academic journey. With a weak foundation in mathematics, I joined the institute in grade 12. Under the patient and dedicated guidance of Vidit Sir, I was able to overcome challenges and achieve a commendable 94% in my board exams. His mentorship has been invaluable.`
  },
  {
    name: `Jhanvi Raghav`,
    subheading: `2023-24 IBDP | Scottish High / Class 12`,
    img: image2,
    shortReview: truncateReview(`Vidit sir is a very thoughtful teacher and makes the class very engaging.`, 80),
    longReview: `Vidit sir is a very thoughtful teacher and makes the class very engaging. He introduces each chapter very well and relates them to the real world which makes everything so much more easier.`
  },
  {
    name: `Vinuthna Ronanki`,
    subheading: `2023-24 IBDP / Scottish High / Class 12`,
    img: image3,
    shortReview: truncateReview(`I am very thankful to Vidit Sir and Shrividhya Classes. Sir helped me identify my weaknesses and also helped me in converting them into my strength.`, 80),
    longReview: `I am very thankful to Vidit Sir and Shrividhya Classes. Sir helped me identify my weaknesses and also helped me in converting them into my strength. The best guidance, tips, various helpful strategies regarding to the syllabus and exam and problem solving method enabled me to perform very well in my academics. His approach towards making us understand the concepts more intuitively and see it in a practical way is what I liked the most. I strongly recommend this to everyone who is keen to learn.`
  },
  {
    name: `Shaurya Jain`,
    subheading: `I Shalom Presidency / Class 10`,
    img: image4,
    shortReview: truncateReview(`I am pleased to commend Shrividhya Classes for their exceptional academic support. Vidit sir's expertise in maths is commendable.`, 80),
    longReview: `I am pleased to commend Shrividhya Classes for their exceptional academic support. Vidit sir's expertise in maths is commendable. His ability to create a conducive learning environment, coupled with his in-depth knowledge, has significantly enhanced my understanding of the subject matter. The personalized attention provided at Shrividhya Classes has undoubtedly contributed to my academic growth. I wholeheartedly recommend the institute to students seeking academic excellence.`
  },
];

export default App;
