import React, { useState } from 'react';
import './about.css';
import emailjs from 'emailjs-com'; // Ensure EmailJS is installed

const About = () => {
  const [contactMethod, setContactMethod] = useState('email');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleToggle = () => {
    setContactMethod(contactMethod === 'email' ? 'phone' : 'email');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (contactMethod === 'phone' && name === 'phone') {
      if (!/^\d*$/.test(value)) return; // Allow only numbers
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data via EmailJS
    emailjs
      .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
      .then(
        (result) => {
          alert('Message Sent Successfully');
        },
        (error) => {
          alert('Failed to send message, please try again.');
        }
      );

    // Clear the form
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  return (
    <section className="contact-form-section">
      <div className="text-container">
        <h1 className="main-heading">Ready to start learning?</h1>
        <h2 className="sub-heading">Create your account now!</h2>
        <p className="body-text">Join our platform and gain access to a wealth of resources and knowledge. Whether you're a student or a teacher, we have something for you.</p>
      </div>
      <div className="form-container">
        <h2>Contact Us</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="toggle-label">
              Contact via {contactMethod === 'email' ? 'Email' : 'Phone'}
              <input
                type="checkbox"
                checked={contactMethod === 'phone'}
                onChange={handleToggle}
              />
              <span className="slider"></span>
            </label>
            {contactMethod === 'email' ? (
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            ) : (
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            )}
          </div>
          <div className="form-group">
            <textarea
              name="message"
              placeholder="Your message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">SEND MESSAGE</button>
        </form>
      </div>
    </section>
  );
};

export default About;
