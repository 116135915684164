import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import './dropdown.css'; // Ensure this file is properly imported and configured

const DropdownMenu = ({ link }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <li key={link.id} className="dropdown-menu-item">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <span className="text-[15px]">{link.title}</span>
        <FaChevronDown className={`dropdown-arrow ${dropdownOpen ? 'open' : ''}`} />
      </div>
      {link.submenu && (
        <ul className={`dropdown ${dropdownOpen ? 'show' : ''}`}>
          {link.submenu.map((sublink) => (
            <li key={sublink.id}>
              <a
                className="text-[15px]"
                href={sublink.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()} // Prevent event bubbling if needed
              >
                {sublink.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default DropdownMenu;
