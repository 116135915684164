import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoImg from "./logo-black.png";
import { LinkData } from "./dummydata";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import DropdownMenu from "./dropdown";
import './header.css';

export const Header = () => {
  const [open, setOpen] = useState(false);

  const handleMenuToggle = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <header className='bg-white py-4 text-black sticky z-50 shadow-md top-0 left-0 w-full'>
      <div className='container flex justify-between items-center'>
        <Link to="/" className='logo flex items-center gap-6'>
          <img src={LogoImg} alt='logo' className='h-16' />
        </Link>
        <nav className={open ? "mobile-view" : "desktop-view"}>
          <ul className='flex items-center gap-6'>
            {LinkData.map((link) => (
              link.submenu ? (
                <DropdownMenu key={link.id} link={link} />
              ) : (
                <li key={link.id}>
                  {link.external ? (
                    <a
                      className="text-[15px]"
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleCloseMenu}
                    >
                      {link.title}
                    </a>
                  ) : (
                    <Link
                      className="text-[15px]"
                      to={link.url}
                      onClick={handleCloseMenu}
                    >
                      {link.title}
                    </Link>
                  )}
                </li>
              )
            ))}
          </ul>
        </nav>
        <div className='account flex items-center gap-5'>
          <button className='open-menu' onClick={handleMenuToggle}>
            <HiOutlineMenuAlt1 size={25} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
