import React from 'react';
import { Link } from 'react-router-dom';
import useScrollToTop from './useScrollToTop'; // Import the custom hook

export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
    external: false
  },
  {
    id: 2,
    title: "About",
    url: "/blog",
    external: false
  },
  {
    id: 3,
    title: "Courses",
    url: "https://ibdp.shrividhya.in/", 
    external: false,
    
  },
  {
    id: 4,
    title: "Contact",
    url: "/Instructor", // Ensure the correct URL is used
    external: false
  },
];

const Navigation = () => {
  useScrollToTop(); // Use the custom hook

  return (
    <nav>
      {LinkData.map(link => (
        link.external ? (
          <a key={link.id} href={link.url} target="_blank" rel="noopener noreferrer">
            {link.title}
          </a>
        ) : link.submenu ? (
          <div key={link.id} className="dropdown">
            <span className="dropdown-title">{link.title}</span>
            <div className="dropdown-content">
              {link.submenu.map(sublink => (
                <a
                  key={sublink.id}
                  href={sublink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {sublink.title}
                </a>
              ))}
            </div>
          </div>
        ) : (
          <Link key={link.id} to={link.url}>
            {link.title}
          </Link>
        )
      ))}
    </nav>
  );
};

export default Navigation;
